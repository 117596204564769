<template>
	<data-table dense :headers="headers" :items="activities" itemType="activity" show-year-filter show-export>
		<template v-slot:addItem>
			<v-row>
				<v-col cols="12">
					<v-text-field hide-details="auto" dense v-model="activity.name" label="Name" outlined></v-text-field>
				</v-col>

				<v-col cols="12"><year-selector v-model="activity.year"></year-selector></v-col>

				<v-col>
					<v-textarea hide-details="auto" dense v-model="activity.rules" label="Rules" outlined></v-textarea>
				</v-col>

				<v-col cols="12">
					<v-autocomplete
						v-model="activity.activityGroupId"
						:items="groups"
						dense
						chips
						small-chips
						label="Groups"
						item-text="name"
						item-value="id"
						hide-details="auto"
						outlined
					></v-autocomplete>
				</v-col>

				<v-col cols="6">
					<v-checkbox
						v-model="activity.commissionBased"
						label="Commission Based?"
						@change="activity.numericalBased = false"
						hide-details="auto"
						dense
					></v-checkbox>
				</v-col>

				<v-col cols="6">
					<v-checkbox
						v-model="activity.numericalBased"
						label="Numerical Based?"
						@change="activity.commissionBased = false"
						hide-details="auto"
						dense
					></v-checkbox>
				</v-col>

				<v-col cols="6">
					<v-checkbox
						v-model="activity.onePerYear"
						label="Limit to one per year?"
						@change="activity.multiplePerDay = false"
						hide-details="auto"
						dense
					></v-checkbox>
				</v-col>

				<v-col cols="6">
					<v-checkbox
						v-model="activity.multiplePerDay"
						label="Allow multiple per day?"
						@change="activity.onePerYear = false"
						hide-details="auto"
						dense
					></v-checkbox>
				</v-col>

				<v-col cols="12">
					<v-text-field
						v-if="!activity.commissionBased"
						v-model.number="activity.points"
						label="Points"
						hide-details="auto"
						outlined
						dense
					></v-text-field>

					<v-text-field
						v-if="activity.commissionBased"
						v-model.number="activity.commissionPercentage"
						label="Commission Percentage"
						hide-details="auto"
						outlined
						dense
					></v-text-field>
				</v-col>

				<v-col cols="12" v-if="activity.numericalBased">
					<v-text-field
						v-model="activity.numericalLabel"
						label="Numerical Label (Time with company etc)"
						hide-details="auto"
						outlined
						dense
					></v-text-field>
				</v-col>
			</v-row>
		</template>
	</data-table>
</template>

<script>
import DataTable from '../components/DataTable.vue';
import YearSelector from '../components/YearSelector.vue';

export default {
	components: { DataTable, YearSelector },

	data() {
		return {
			headers: [
				{ text: 'Name', value: 'name' },
				{ text: 'Year', value: 'year' },
				{ text: 'Rules', value: 'rules' },
				{ text: 'Group', value: 'group' },
				{ text: 'Points', value: 'points' },
				{ text: 'Commision Based', value: 'commissionBased', permission: 'AdminOnly' },
				{ text: 'Numerical Based', value: 'numericalBased', permission: 'AdminOnly' },
				{ text: 'Numerical Label', value: 'numericalLabel', permission: 'AdminOnly' },
				{ text: 'One Per Year', value: 'onePerYear', permission: 'AdminOnly' },
				{ text: 'Multiple Per Day', value: 'multiplePerDay', permission: 'AdminOnly' },
			],
		};
	},
	mounted() {
		this.$store.dispatch('get', 'activity');
		this.$store.dispatch('get', 'group');

		let activity = {
			name: '',
			year: null,
			points: 0,
			activityGroupId: '',
			commissionBased: false,
			numericalBased: false,
			numericalLabel: '',
			onePeryear: false,
			multiplePerDay: false,
		};

		this.$store.commit('SET_EDITED_ITEM', activity);
		this.$store.commit('SET_DEFAULT_ITEM', activity);
	},

	computed: {
		activities() {
			let formatted = [];

			this.$store.getters.activities.forEach((a) => {
				if (a.commissionBased) a.points = '%';

				formatted.push(a);
			});

			return formatted;
		},

		groups() {
			return this.$store.getters.groups;
		},

		activity: {
			get() {
				return this.$store.state.editedItem;
			},
			set(value) {
				this.$store.commit('SET_EDITED_ITEM', value);
			},
		},
	},
};
</script>

<style></style>
