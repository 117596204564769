<template>
	<div>
		<v-select
			label="Year"
			:items="years"
			:value="year"
			@input="$emit('input', $event)"
			hide-details="auto"
			outlined
			dense
		></v-select>
	</div>
</template>

<script>
export default {
	props: {
		value: { type: Number },
		getYearsFromActivities: { type: Boolean, default: false },
	},

	data() {
		return {
			year: new Date().getFullYear(),
			years: [],
		};
	},

	methods: {
		async getYears() {
			let startYear = 2021; // When point maker was first used
			let thisYear = new Date().getFullYear();

			while (thisYear >= startYear) {
				this.years.push(thisYear);
				thisYear--;
			}

			if (this.getYearsFromActivities) {
				const result = await this.$store.dispatch('GetYears');

				this.years = result?.data;
			}
		},
	},

	watch: {
		value: {
			immediate: true,
			handler(val) {
				if (val) {
					this.year = val;
				} else {
					this.$emit('input', this.year);
				}
			},
		},
	},

	async created() {
		await this.getYears();
	},
};
</script>

<style></style>
