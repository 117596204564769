import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '../store/index.js';
import Activities from '../views/Activities.vue';
import Currencies from '../views/Currencies.vue';
import ExchangeRates from '../views/ExchangeRates.vue';
import Goals from '../views/Goals.vue';
import GoalsProgress from '../views/GoalsProgress.vue';
import Groups from '../views/Groups.vue';
import Home from '../views/Home.vue';
import Login from '../views/Login.vue';
import Points from '../views/Points.vue';
import PointsAnalysis from '../views/PointsAnalysis.vue';
import SSO from '../views/SSO.vue';
import Users from '../views/Users.vue';
Vue.use(VueRouter);

const routes = [
	{
		path: '/login',
		name: 'Login',
		component: Login,
		meta: {
			requiresAuth: false,
		},
	},
	{
		path: '/home',
		name: 'Home',
		component: Home,
		meta: {
			requiresAuth: true,
		},
	},
	{
		path: '/points',
		name: 'Points',
		component: Points,
		meta: {
			requiresAuth: true,
		},
	},
	{
		path: '/goalsProgress',
		name: 'GoalsProgress',
		component: GoalsProgress,
		meta: {
			requiresAuth: true,
		},
	},
	{
		path: '/sso',
		name: 'SSO',
		component: SSO,
		meta: {
			requiresAuth: false,
		},
	},
	{
		path: '/PointsAnalysis',
		name: 'PointsAnalysis',
		component: PointsAnalysis,
		meta: {
			requiresAuth: true,
		},
	},
	{
		path: '/activities',
		name: 'Activities',
		component: Activities,
		meta: {
			requiresAuth: true,
		},
	},
	{
		path: '/users',
		name: 'Users',
		component: Users,
		meta: {
			requiresAuth: true,
		},
	},
	{
		path: '/groups',
		name: 'Groups',
		component: Groups,
		meta: {
			requiresAuth: true,
		},
	},
	{
		path: '/goals',
		name: 'Goals',
		component: Goals,
		meta: {
			requiresAuth: true,
		},
	},
	{
		path: '/exchangeRates',
		name: 'ExchangeRates',
		component: ExchangeRates,
		meta: {
			requiresAuth: true,
		},
	},
	{
		path: '/currencies',
		name: 'Currencies',
		component: Currencies,
		meta: {
			requiresAuth: true,
		},
	},
	{
		path: '*',
		redirect: '/login',
	},
];

const router = new VueRouter({
	mode: 'history',
	routes,
});

router.beforeEach(async (to, from, next) => {
	if (to.name == 'login' && to.query.sso) {
		window.location.href = `https://sea-hub-d26fc.web.app?redirect=${process.env.VUE_APP_URL}/sso`;
	}

	if (to.name == 'SSO') {
		await store.dispatch('authenticate', { redirect: false });
		if (store.state.isAuthenticated) {
			next('home');
		} else {
			next();
		}
		return;
	}

	if (to.meta.requiresAuth && !store.state.isAuthenticated) {
		const isAuthenticated = await store.dispatch('authenticate', { redirect: false });

		if (isAuthenticated) next();
		else next({ name: 'Login' });
	} else if (to.name === null) next({ name: 'Login' });
	else {
		next();
	}
});

export default router;
