var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"width":"50%"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v(_vm._s(_vm.formTitle))])]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Select Date...","prepend-icon":"fas fa-calendar-day","readonly":""},model:{value:(_vm.dateDisplay),callback:function ($$v) {_vm.dateDisplay=$$v},expression:"dateDisplay"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{on:{"input":function($event){_vm.menu = false}},model:{value:(_vm.dateEvents.date),callback:function ($$v) {_vm.$set(_vm.dateEvents, "date", $$v)},expression:"dateEvents.date"}})],1)],1)],1),_c('v-row',[_c('v-col',[_c('v-autocomplete',{attrs:{"id":"activitiesSelect","value":_vm.uniqueUserActivities,"items":_vm.groupedActivities,"dense":"","chips":"","small-chips":"","label":"Activities","multiple":"","prepend-icon":"far fa-calendar-plus","item-text":"name","item-value":"activityVersionId","return-object":"","menu-props":{
								maxHeight: 550,
								attach: '#activitiesSelect',
								bottom: true,
								offsetY: true,
								allowOverflow: true,
							}},on:{"change":_vm.update}})],1)],1),_c('v-row',[_c('v-col',_vm._l((_vm.descriptions),function(description){return _c(description,{key:description.label,tag:"component"})}),1)],1)],1)],1),_c('v-card-actions',[_c('v-btn',{on:{"click":_vm.close}},[_vm._v(" Close ")]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"success","dark":""},on:{"click":_vm.save}},[_vm._v(" Save ")])],1)],1),_c('v-snackbar',{attrs:{"timeout":3000},scopedSlots:_vm._u([{key:"action",fn:function({ attrs }){return [_c('v-btn',_vm._b({attrs:{"color":"pink","text":""},on:{"click":_vm.clearError}},'v-btn',attrs,false),[_vm._v(" Close ")])]}}]),model:{value:(_vm.error),callback:function ($$v) {_vm.error=$$v},expression:"error"}},[_vm._v(" "+_vm._s(_vm.errorMessage)+" ")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }