<template>
	<div>
		<v-dialog v-model="dialog" max-width="550px">
			<template v-slot:activator="{ on, attrs }">
				<v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
					<v-icon small class="mr-2">fas fa-plus</v-icon>
					New {{ title }}
				</v-btn>
			</template>
			<v-card>
				<v-card-title>
					<span class="headline">{{ formTitle }}</span>
				</v-card-title>

				<v-card-text>
					<slot></slot>
				</v-card-text>

				<v-card-actions>
					<v-btn @click="close">
						Cancel
					</v-btn>
					<v-spacer></v-spacer>
					<v-btn color="success" @click="save">
						Save
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<v-dialog v-model="dialogDelete" max-width="500px">
			<v-card>
				<v-card-title class="headline">Are you sure you want to delete this item?</v-card-title>
				<v-card-actions>
					<v-btn @click="closeDelete">Cancel</v-btn>
					<v-spacer></v-spacer>
					<v-btn color="red" dark @click="deleteItemConfirm">Delete</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
import startCase from 'lodash.startcase';

export default {
	props: {
		itemType: String,
		items: Array,
		item: Object,
		editedItem: Object,
		deletedItem: Object,
	},
	data() {
		return {
			dialog: false,
			dialogDelete: false,
			editedIndex: -1,
		};
	},
	computed: {
		formTitle() {
			return this.editedIndex === -1 ? 'New ' + this.title : 'Edit ' + this.title;
		},
		title() {
			return startCase(this.itemType);
		},
		defaultItem: {
			get() {
				return this.$store.state.defaultItem;
			},
			set(value) {
				this.$store.commit('SET_DEFAULT_ITEM', value);
			},
		},
	},
	watch: {
		dialog(val) {
			val || this.close();
		},
		dialogDelete(val) {
			val || this.closeDelete();
		},
		editedItem(val) {
			if (val) this.editItem(val);
		},
		deletedItem(val) {
			this.deleteItem(val);
		},
	},
	methods: {
		editItem(item) {
			this.editedIndex = this.items.indexOf(item);
			this.$store.commit('SET_EDITED_ITEM', Object.assign({}, item));
			this.dialog = true;
		},

		deleteItem(item) {
			this.editedIndex = this.items.indexOf(item);
			this.$store.commit('SET_EDITED_ITEM', Object.assign({}, item));
			this.dialogDelete = true;
		},

		deleteItemConfirm() {
			this.$store.dispatch('delete', this.itemType, this.editedItem);
			this.closeDelete();
		},

		close() {
			this.dialog = false;
			this.$store.commit('CLEAR_EDITED_ITEM');
			this.editedIndex = -1;
			this.$emit('close');
		},

		closeDelete() {
			this.dialogDelete = false;
			this.$store.commit('CLEAR_EDITED_ITEM');
			this.editedIndex = -1;
			this.$emit('close');
		},

		save() {
			if (this.editedIndex > -1) {
				//edit
				this.$store.dispatch('update', this.itemType);
			} else {
				//save
				this.$store.dispatch('add', this.itemType);
			}
			this.close();
		},
	},
};
</script>

<style></style>
