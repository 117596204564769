<template>
	<data-table
		:headers="headers"
		:items="league"
		show-export
		show-year-filter
		read-only
		itemType="point"
		@yearChange="onYearChange($event)"
		sortBy="points"
		sort-desc
	></data-table>
</template>

<script>
import DataTable from '../components/DataTable.vue';

export default {
	components: { DataTable },

	data() {
		return {
			headers: [
				{ text: 'Name', value: 'name' },
				{ text: 'Office', value: 'office' },
				{ text: 'Points', value: 'points' },
			],
			year: new Date().getFullYear(),
		};
	},

	computed: {
		league() {
			return this.$store.getters.league.map((item, index) => ({
				...item,
				position: index + 1,
				year: this.year,
			}));
		},
	},

	methods: {
		async getPoints() {
			await this.$store.dispatch('GetPoints', this.year);
		},

		async onYearChange(year) {
			this.year = year;
			await this.getPoints();
		},
	},

	async mounted() {
		await this.getPoints();
	},
};
</script>

<style></style>
