<template>
      <v-row>
        <v-col cols="12">Signing In...</v-col>
      </v-row>
</template>

<script>
export default {
  name: "sso",
  components: {},
  data: function () {
    return {};
  },
  methods: {},
  computed: {},
  mounted: function () {
    this.$store.dispatch("tokenLogin");
  },
};
</script>
