<template>
	<v-row align="center" justify="center">
		<v-col sm="8" md="6" lg="4">
			<v-card class="mt-16" elevation="5">
				<v-card-title class="justify-center">
					<v-img  max-width="80%" :src="require('@/assets/images/seagoal-logo.png')"></v-img>
				</v-card-title>

				<v-form ref="form" v-model="valid">
					<v-container>
						<v-row>
							<v-col>
								<v-text-field
									v-model="email"
									:rules="emailRules"
									label="E-mail"
									required
								></v-text-field>
							</v-col>
						</v-row>

						<v-row>
							<v-col>
								<v-text-field
									v-model="password"
									:append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
									:type="show1 ? 'text' : 'password'"
									:rules="passwordRules"
									@click:append="show1 = !show1"
									label="Password"
									required
								></v-text-field>
							</v-col>
						</v-row>

						<v-row>
							<v-col>
								<v-btn
									:disabled="!valid"
									@click="validate"
									color="primary"
									class="px-16"
									large
									:loading="loading"
								>
									Login
								</v-btn>

								<reset-password class="float-right"></reset-password>
							</v-col>
						</v-row>
					</v-container>
				</v-form>
			</v-card>

			<v-snackbar v-model="loginFailed">
				Login Failed

				<template v-slot:action="{ attrs }">
					<v-btn color="pink" text v-bind="attrs" @click="loginFailed = false">
						Close
					</v-btn>
				</template>
			</v-snackbar>
		</v-col>
	</v-row>
</template>

<script>
import ResetPassword from './ResetPassword.vue';

export default {
	components: { ResetPassword },
	name: 'LoginForm',
	data: () => ({
		valid: false,
		email: '',
		emailRules: [
			(v) => !!v || 'E-mail is required',
			(v) => /.+@.+/.test(v) || 'E-mail must be valid',
		],
		password: '',
		passwordRules: [(v) => !!v || 'Password is required'],
		loginFailed: false,
		show1: false,
		loading: false,
	}),
	methods: {
		validate() {
			this.loading = true;

			let valid = this.$refs.form.validate();

			if (valid) this.login();
		},
		login() {
			this.$store.dispatch('login', {
				email: this.email,
				password: this.password,
				rememberMe: false,
			});
		},
	},
	watch: {
		loginResponse(x) {
			this.loginFailed = true;
			this.loading = false;
		},
	},
	computed: {
		loginResponse() {
			return this.$store.getters.loginResponse;
		},
	},
};
</script>

<style></style>
