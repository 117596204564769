<template>
	<v-container fluid fill-height>
		<v-row>
			<v-col><p class="text-h3">Goal Progress</p></v-col>
			<v-spacer></v-spacer>
			<v-col><year-selector v-model="year" get-years-from-activities></year-selector></v-col>
		</v-row>

		<template v-if="goals && goals.length > 0">
			<v-row dense v-for="office in offices" :key="office">
				<v-col>
					<v-card>
						<v-card-title class="text-h5">{{ office }}</v-card-title>
						<v-card-text>
							<template v-if="goalsFilter(office).length > 0">
								<v-slide-group v-model="slideModel" show-arrows>
									<v-slide-item v-for="goal in goalsFilter(office)" :key="goal.description" class="mx-2 my-1">
										<v-card class="d-flex flex-column" height="250" width="350">
											<v-card-title class="text-subtitle-1 wordBreak">
												{{ goal.description }}
											</v-card-title>
											<v-divider></v-divider>
											<v-card-subtitle>{{ goal.progress }}</v-card-subtitle>
										</v-card>
									</v-slide-item>
								</v-slide-group>
							</template>
							<div v-else>No goals have been created for this office</div>
						</v-card-text>
					</v-card>
				</v-col>
			</v-row>
		</template>

		<v-row class="fill-height" justify="space-around" v-else>
			<v-col cols="3">
				<span class="text-h5">No goals found for this year</span>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import YearSelector from '../components/YearSelector.vue';

export default {
	components: { YearSelector },

	data: () => ({ slideModel: null, year: null }),

	methods: {
		goalsFilter(filter) {
			return this.goals.filter((x) => x.office === filter);
		},
	},

	computed: {
		goals() {
			let flex = this.$store.getters.goals;

			flex.forEach((element) => {
				element.flex = 2;
			});

			return flex;
		},

		offices() {
			return this.$store.getters.offices;
		},
	},

	watch: {
		async year(val) {
			if (val) {
				await this.$store.dispatch('getGoals', val);
			}
		},
	},
};
</script>

<style scoped>
.wordBreak {
	word-break: break-word;
}
</style>
