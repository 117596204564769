<template>
	<data-table :headers="headers" :items="users" itemType="user" :item="user">
		<template v-slot:addItem>
			<v-row>
				<v-col>
					<v-text-field v-model="user.name" label="Name"></v-text-field>
				</v-col>
			</v-row>

			<v-row>
				<v-col>
					<v-text-field v-model="user.emailAddress" label="Email"></v-text-field>
				</v-col>
			</v-row>

			<v-row>
				<v-col>
					<v-select :items="offices" v-model="user.office" label="Office"></v-select>
				</v-col>
			</v-row>

			<v-row>
				<v-col>
					<v-select :items="roles" v-model="user.role" label="Role"></v-select>
				</v-col>
			</v-row>

			<v-row>
				<v-col>
					<v-select :items="managers" v-model="user.managerId" item-text="name" item-value="id" label="Manager"></v-select>
				</v-col>
			</v-row>
		</template>
	</data-table>
</template>

<script>
import DataTable from '../components/DataTable.vue';
export default {
	components: { DataTable },
	data() {
		return {
			headers: [
				{ text: 'Name', value: 'name' },
				{ text: 'Email', value: 'emailAddress' },
				{ text: 'Office', value: 'office' },
				{ text: 'Role', value: 'role' },
				{ text: 'Manager', value: 'manager' },
			],
		};
	},
	mounted() {
		this.$store.dispatch('get', 'User');
		this.$store.dispatch('get', 'Managers');

		let user = { name: '', emailaddress: '', office: '', role: '', managerId: null };

		this.$store.commit('SET_EDITED_ITEM', user);
		this.$store.commit('SET_DEFAULT_ITEM', user);
	},
	computed: {
		users() {
			return this.$store.getters.users;
		},
		managers() {
			return this.$store.getters.managers;
		},
		roles() {
			return this.$store.getters.roles;
		},
		offices() {
			return this.$store.getters.offices.filter((x) => x !== 'Group');
		},
		user: {
			get() {
				return this.$store.state.editedItem;
			},
			set(value) {
				this.$store.commit('SET_EDITED_ITEM', value);
			},
		},
	},
};
</script>

<style></style>
