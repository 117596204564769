<template>
	<v-container fluid fill-height>
		<v-row>
			<v-col class="py-0">
				<v-sheet class="py-2">
					<v-toolbar flat>
						<points-summary :points="calendarUser.points"></points-summary>

						<v-menu bottom right>
							<template v-slot:activator="{ on, attrs }">
								<v-btn outlined color="grey darken-2" v-bind="attrs" v-on="on">
									<span>{{ typeToLabel[type] }}</span>
									<v-icon right>
										mdi-menu-down
									</v-icon>
								</v-btn>
							</template>

							<v-list>
								<v-list-item v-if="type == 'day'" @click="type = 'day'">
									<v-list-item-title>Day</v-list-item-title>
								</v-list-item>
								<v-list-item @click="type = 'week'">
									<v-list-item-title>Week</v-list-item-title>
								</v-list-item>
								<v-list-item @click="type = 'month'">
									<v-list-item-title>Month</v-list-item-title>
								</v-list-item>
							</v-list>
						</v-menu>

						<v-btn-toggle v-model="btnToggle" class="mx-4">
							<v-btn fab text color="grey darken-2" @click="prev">
								<v-icon>
									mdi-chevron-left
								</v-icon>
							</v-btn>
							<v-btn fab text color="grey darken-2" @click="next">
								<v-icon>
									mdi-chevron-right
								</v-icon>
							</v-btn>
						</v-btn-toggle>

						<v-toolbar-title v-if="$refs.calendar" class="mr-4">
							{{ $refs.calendar.title }}
						</v-toolbar-title>

						<v-divider vertical class="mr-4"></v-divider>

						<v-toolbar-title>{{ calendarUser.name }}</v-toolbar-title>

						<v-spacer></v-spacer>

						<v-col md="3" class="mt-5">
							<v-autocomplete
								v-if="isAdminOrManager"
								v-model="calendarUser"
								:items="users"
								item-text="name"
								item-value="id"
								label="Select a User..."
								return-object
							></v-autocomplete>
						</v-col>

						<add-user-activity></add-user-activity>
					</v-toolbar>
				</v-sheet>
			</v-col>
		</v-row>
		<v-row id="calendarRow">
			<v-col class="py-0">
				<v-sheet :class="type === 'month' ? 'fill-height' : ''">
					<v-calendar
						ref="calendar"
						v-model="focus"
						color="primary"
						:type="type"
						:weekdays="weekdays"
						:events="userActivities"
						:value="startOfMonth"
						interval-count="0"
						:event-height="eventHeight"
						:event-margin-bottom="eventMarginBottom"
						@change="init"
						@click:day="getEvents"
						@click:date="getEvents"
						@click:event="showEvent"
						@click:more="viewDay"
					>
						<template v-slot:event="{ event }">
							<v-row class="pl-1" dense>
								<v-col sm="8" md="8" lg="9" xl="10" class="wrap">{{ event.name }}</v-col>
								<v-spacer></v-spacer>
								<v-col v-if="isAdminOrManager" class="px-0" sm="4" md="4" lg="3" xl="2">
									<div class="pr-2 pt-1 float-right">
										<v-btn icon color="white" :x-small="xSmall" :small="small" @click="approve(event)">
											<v-icon>fas fa-check</v-icon>
										</v-btn>
										<v-btn icon color="white" :x-small="xSmall" :small="small" @click="reject(event)">
											<v-icon>fas fa-times</v-icon>
										</v-btn>
									</div>
								</v-col>
							</v-row>
						</template>
					</v-calendar>
					<v-menu v-model="selectedOpen" :close-on-content-click="false" :open-on-click="false" :activator="selectedElement" offset-x>
						<v-card color="grey lighten-4" min-width="350px" flat>
							<v-toolbar :color="selectedEvent.color" dark>
								<v-toolbar-title v-html="selectedEvent.name"></v-toolbar-title>
							</v-toolbar>

							<v-card-text>
								<v-textarea v-model="selectedEvent.userComment" label="User Comment"></v-textarea>

								<v-divider class="mb-2"></v-divider>

								<v-textarea :readonly="!isAdminOrManager" v-model="selectedEvent.managerComment" label="Manager Comment"></v-textarea>

								<v-divider class="mb-2"></v-divider>

								<v-textarea v-if="isAdminOrManager" v-model="selectedEvent.managerOnlyComment" label="Manager Only Comment"></v-textarea>
							</v-card-text>

							<v-card-actions>
								<v-btn text color="secondary" @click="selectedOpen = false">
									Cancel
								</v-btn>

								<v-spacer></v-spacer>

								<div v-if="isAdminOrManager">
									<v-btn icon @click="approve(null)">
										<v-icon>fas fa-check</v-icon>
									</v-btn>
									<v-btn icon @click="reject(null)">
										<v-icon>fas fa-times</v-icon>
									</v-btn>
								</div>
								<div v-else>
									<v-btn text color="primary" @click="updateUserComment(event)">
										Save Comment
									</v-btn>
								</div>
							</v-card-actions>
						</v-card>
					</v-menu>
				</v-sheet>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import { DateTime } from 'luxon';
import AddUserActivity from '../components/AddUserActivity.vue';
import PointsSummary from '../components/PointsSummary.vue';

export default {
	data: () => ({
		focus: '',
		type: 'month',
		weekdays: [1, 2, 3, 4, 5, 6, 0],
		btnToggle: null,
		startOfMonth: DateTime.utc()
			.startOf('month')
			.toISODate(),
		selectedEvent: {},
		selectedElement: null,
		selectedOpen: false,
	}),

	components: {
		AddUserActivity,
		PointsSummary,
	},

	watch: {
		calendarUser(val) {
			this.$store.dispatch('getUserActivities', val.id);
			this.$store.dispatch('getUserPoints', val.id);
		},

		year(oldYear, newYear) {
			if (oldYear !== newYear) {
				this.$store.dispatch('GetActivities', this.year);
			}
		},
	},

	mounted() {
		if (this.isAdminOrManager) this.$store.dispatch('get', 'user');

		let dateEvents = {
			userId: this.calendarUser.id,
			date: '',
			activities: [],
			init: true,
		};

		this.$store.commit('SET_EDITED_ITEM', dateEvents);
		this.$store.commit('SET_DEFAULT_ITEM', dateEvents);
	},

	methods: {
		async getCalendarUserPoints() {
			await this.$store.dispatch('getUserPoints', this.calendarUser.id);
		},

		viewDay({ date }) {
			this.focus = date;
			this.type = 'day';
		},

		prev() {
			this.$refs.calendar.prev();
		},

		next() {
			this.$refs.calendar.next();
		},
		updateUserComment(event) {
			if (!event) event = this.selectedEvent;

			this.$store
				.dispatch('updateUserComment', {
					userActivityId: event.id,
					userComment: event.userComment,
				})
				.then(() => {
					this.selectedOpen = false;
				});
		},

		async approve(event) {
			if (!event) event = this.selectedEvent;

			await this.$store.dispatch('setUserActivityStatus', {
				userActivityId: event.id,
				status: 'Approved',
				managerComment: event.managerComment,
				managerOnlyComment: event.managerOnlyComment,
				userComment: event.userComment,
			});

			this.selectedOpen = false;

			await this.getCalendarUserPoints();
		},

		async reject(event) {
			if (!event) event = this.selectedEvent;

			await this.$store.dispatch('setUserActivityStatus', {
				userActivityId: event.id,
				status: 'Rejected',
				managerComment: event.managerComment,
				managerOnlyComment: event.managerOnlyComment,
				userComment: event.userComment,
			});

			this.selectedOpen = false;

			await this.getCalendarUserPoints();
		},

		eventColor(status) {
			switch (status) {
				case 'Approved':
					return 'success';
				case 'Rejected':
					return 'warning';
				default:
					return 'primary';
			}
		},

		showEvent({ nativeEvent, event }) {
			const open = () => {
				this.selectedEvent = event;
				this.selectedElement = nativeEvent.target;
				setTimeout(() => {
					this.selectedOpen = true;
				}, 10);
			};

			if (this.selectedOpen) {
				this.selectedOpen = false;
				setTimeout(open, 10);
			} else {
				open();
			}

			nativeEvent.stopPropagation();
		},

		init() {
			this.$store.commit('SET_MONTH', this.$refs.calendar.parsedValue.month);
			this.$store.commit('SET_YEAR', this.$refs.calendar.parsedValue.year);

			if (!this.isAdminOrManager) this.calendarUser = this.$store.getters.user;

			if (this.calendarUser.id !== '') this.$store.dispatch('getUserActivities');
		},

		getEvents({ date }) {
			let today = DateTime.local();
			let selected = DateTime.fromISO(date);

			if (selected > today && !this.isAdminOrManager) return;

			this.userActivity = {
				userId: this.calendarUser.id,
				date: date,
				activities: this.$refs.calendar.events
					.filter((x) => x.start == date)
					.map((x) => {
						return {
							id: x.id,
							activityVersionId: x.activityVersionId,
							activityId: x.activityId,
							name: x.name,
							group: x.group,
							userComment: x.userComment,
							managerComment: x.managerComment,
							managerOnlyComment: x.managerOnlyComment,
							commissionBased: x.commissionBased,
							commissionCurrencyId: x.commissionCurrencyId,
							commissionValue: x.commissionValue,
							numericalBased: x.numericalBased,
							numericalLabel: x.numericalLabel,
							numericalValue: x.numericalValue,
							multiplePerDay: x.multiplePerDay,
						};
					}),
				init: false,
			};
		},
	},

	computed: {
		xSmall() {
			return this.type !== 'day';
		},

		small() {
			return this.type === 'day';
		},

		typeToLabel() {
			if (this.type === 'day') {
				return {
					month: 'Month',
					week: 'Week',
					day: 'Day',
				};
			} else
				return {
					month: 'Month',
					week: 'Week',
				};
		},

		year() {
			return this.$store.getters.year;
		},

		eventHeight() {
			if (this.type === 'day') return 40;
			else return 40;
		},

		eventMarginBottom() {
			if (this.type === 'day') return 5;
			else return 1;
		},

		calendarUser: {
			get() {
				return this.$store.getters.calendarUser;
			},
			set(value) {
				this.$store.commit('SET_CALENDAR_USER', value);
			},
		},

		users() {
			return this.$store.getters.users
				.filter((x) => x.role === 'User')
				.map((x) => {
					return { id: x.id, name: x.name, points: [] };
				});
		},

		userActivities() {
			let formatted = this.$store.getters.userActivities;

			formatted.forEach((x) => {
				x.start = DateTime.fromISO(x.start).toFormat('yyyy-MM-dd');
				x.color = this.eventColor(x.status);
			});

			return formatted;
		},

		userActivity: {
			get() {
				return this.$store.getters.editedItem;
			},
			set(value) {
				this.$store.commit('SET_EDITED_ITEM', value);
			},
		},

		isAdminOrManager() {
			return this.$store.getters.isAdminOrManager;
		},
	},
};
</script>

<style lang="scss" scoped>
.v-calendar {
	.v-btn {
		font-size: 16px;
	}

	.v-calendar-weekly__head-weekday {
		font-size: 18px;
	}
	.wrap {
		white-space: break-spaces;
	}
}

#calendarRow {
	height: 90%;
}
</style>
