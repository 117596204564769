<template>
	<div>
		<v-app-bar app color="primary" dark>
			<v-app-bar-nav-icon @click="drawer = true"></v-app-bar-nav-icon>

			<v-toolbar-title>SeaGoals</v-toolbar-title>
		</v-app-bar>
		<v-navigation-drawer v-model="drawer" app temporary>
			<v-list-item>
				<v-list-item-avatar size="48">
					<v-avatar color="primary">
						<span class="white--text headline">{{ this.initials }}</span>
					</v-avatar>
				</v-list-item-avatar>

				<v-list-item-content>
					<v-list-item-title>{{ this.$store.state.user.name }}</v-list-item-title>
				</v-list-item-content>
			</v-list-item>

			<v-divider></v-divider>

			<v-list>
				<v-list-item link to="/home" v-if="!isGoalsUser">
					<v-list-item-icon>
						<v-icon>fas fa-home</v-icon>
					</v-list-item-icon>

					<v-list-item-content>
						<v-list-item-title>Home</v-list-item-title>
					</v-list-item-content>
				</v-list-item>

				<v-list-item link to="/points" v-if="isAdminOrManager">
					<v-list-item-icon>
						<v-icon>fas fa-list-ol</v-icon>
					</v-list-item-icon>

					<v-list-item-content>
						<v-list-item-title>Points</v-list-item-title>
					</v-list-item-content>
				</v-list-item>

				<v-list-item link to="/goalsProgress">
					<v-list-item-icon>
						<v-icon>fas fa-bullseye</v-icon>
					</v-list-item-icon>

					<v-list-item-content>
						<v-list-item-title>Goals Progress</v-list-item-title>
					</v-list-item-content>
				</v-list-item>

				<v-list-item link to="/pointsAnalysis" v-if="isAdminOrManager">
					<v-list-item-icon>
						<v-icon>fas fa-chart-bar</v-icon>
					</v-list-item-icon>

					<v-list-item-content>
						<v-list-item-title>Points Analysis</v-list-item-title>
					</v-list-item-content>
				</v-list-item>

				<v-divider></v-divider>

				<div v-if="isAdminOrManager">
					<v-list-item link to="/users">
						<v-list-item-icon>
							<v-icon>fas fa-users</v-icon>
						</v-list-item-icon>

						<v-list-item-content>
							<v-list-item-title>Users</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
				</div>

				<v-list-item link to="/activities" v-if="isAdminOrManager">
					<v-list-item-icon>
						<v-icon>far fa-calendar-plus</v-icon>
					</v-list-item-icon>

					<v-list-item-content>
						<v-list-item-title>Activities</v-list-item-title>
					</v-list-item-content>
				</v-list-item>

				<div v-if="isGoalsAdmin">
					<v-list-item link to="/groups" v-if="isAdminOrManager">
						<v-list-item-icon>
							<v-icon>fas fa-layer-group</v-icon>
						</v-list-item-icon>

						<v-list-item-content>
							<v-list-item-title>Activity Groups</v-list-item-title>
						</v-list-item-content>
					</v-list-item>

					<v-list-item link to="/Goals" v-if="isGoalsAdmin">
						<v-list-item-icon>
							<v-icon>fas fa-bullseye</v-icon>
						</v-list-item-icon>

						<v-list-item-content>
							<v-list-item-title>Goals</v-list-item-title>
						</v-list-item-content>
					</v-list-item>

					<v-list-item link to="/ExchangeRates" v-if="isAdminOrManager">
						<v-list-item-icon>
							<v-icon>fas fa-coins</v-icon>
						</v-list-item-icon>

						<v-list-item-content>
							<v-list-item-title>Exchange Rates</v-list-item-title>
						</v-list-item-content>
					</v-list-item>

					<v-list-item link to="/Currencies" v-if="isAdminOrManager">
						<v-list-item-icon>
							<v-icon>fas fa-coins</v-icon>
						</v-list-item-icon>

						<v-list-item-content>
							<v-list-item-title>Currencies</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
				</div>

				<v-divider></v-divider>
				<v-list-item @click="resetPassword">
					<v-list-item-icon>
						<v-icon>fas fa-unlock</v-icon>
					</v-list-item-icon>

					<v-list-item-content>
						<v-list-item-title>Send Reset Password Email</v-list-item-title>
					</v-list-item-content>
				</v-list-item>

				<v-list-item @click="logout">
					<v-list-item-icon>
						<v-icon>fas fa-sign-out-alt</v-icon>
					</v-list-item-icon>

					<v-list-item-content>
						<v-list-item-title>Logout</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
			</v-list>
		</v-navigation-drawer>
	</div>
</template>

<script>
export default {
	name: 'AppBar',
	data: () => ({
		drawer: null,
		group: null,
	}),
	computed: {
		initials() {
			let name = this.$store.state.user.name;
			let rgx = new RegExp(/(\p{L}{1})\p{L}+/, 'gu');
			let initials = [...name.matchAll(rgx)] || [];

			initials = ((initials.shift()?.[1] || '') + (initials.pop()?.[1] || '')).toUpperCase();

			return initials;
		},

		isAdmin() {
			return this.$store.getters.isAdmin;
		},

		isGoalsAdmin() {
			return this.$store.getters.isGoalsAdmin;
		},

		isAdminOrManager() {
			return this.$store.getters.isAdminOrManager;
		},

		isGoalsUser() {
			return this.$store.getters.isGoalsUser;
		},
	},
	methods: {
		logout() {
			this.$store.dispatch('logout');
		},
		resetPassword() {
			this.$store.dispatch('resetPassword', this.$store.getters.user.email);
		},
	},
};
</script>

<style scoped>
.v-list-item__title {
	white-space: break-spaces;
}
</style>
