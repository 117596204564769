export let api = {
	activity: {
		C: 'AddActivity',
		R: 'GetActivities',
		U: 'UpdateActivity',
		D: 'DeleteActivity',
		controller: 'Activity',
		setter: 'SET_ACTIVITIES',
	},
	group: {
		C: 'AddGroup',
		R: 'GetGroups',
		U: 'UpdateGroup',
		D: 'DeleteGroup',
		controller: 'Activity',
		setter: 'SET_GROUPS',
	},
	exchangerate: {
		C: 'Add',
		R: 'Get',
		U: 'Update',
		D: 'Delete',
		controller: 'ExchangeRate',
		setter: 'SET_EXCHANGE_RATES',
	},
	currency: {
		C: 'Add',
		R: 'Get',
		U: 'Update',
		D: 'Delete',
		controller: 'Currency',
		setter: 'SET_CURRENCIES',
	},
	useractivities: {
		C: 'AddUserActivities',
		R: 'GetUserActivities',
		U: 'UpdateUserActivities',
		D: 'Delete',
		controller: 'Activity',
		setter: 'SET_USER_ACTIVITIES',
	},
	user: {
		C: 'Add',
		R: 'Get',
		U: 'Update',
		D: 'Delete',
		controller: 'User',
		setter: 'SET_USERS',
	},
	managers: {
		R: 'GetManagers',
		controller: 'User',
		setter: 'SET_MANAGERS',
	},
	goal: {
		C: 'Add',
		R: 'Get',
		U: 'Update',
		D: 'Delete',
		controller: 'Goal',
		setter: 'SET_GOALS',
	},
	league: {
		R: 'GetLeague',
		controller: 'Activity',
		setter: 'SET_LEAGUE',
	},

	getRoute(type, action) {
		let actions = api[type.toLowerCase()];

		return actions.controller + '/' + actions[action];
	},

	getSetter(type) {
		return api[type.toLowerCase()].setter;
	},
};
