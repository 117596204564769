<template>
	<data-table :headers="headers" :items="goals" itemType="goal" :item="goal" show-export show-year-filter>
		<template v-slot:addItem>
			<v-row>
				<v-col cols="12">
					<v-text-field dense outlined hide-details v-model="goal.description" label="Description"></v-text-field>
				</v-col>

				<v-col cols="12">
					<v-select dense outlined hide-details :items="offices" v-model="goal.office" label="Office"></v-select>
				</v-col>

				<v-col cols="12">
					<year-selector v-model.number="goal.year"></year-selector>
				</v-col>

				<v-col cols="12">
					<v-textarea outlined hide-details v-model="goal.progress" label="Progress"></v-textarea>
				</v-col>
			</v-row>
		</template>
	</data-table>
</template>

<script>
import DataTable from '../components/DataTable.vue';
import YearSelector from '../components/YearSelector.vue';

export default {
	components: { DataTable, YearSelector },

	data() {
		return {
			headers: [
				{ text: 'Description', value: 'description' },
				{ text: 'Office', value: 'office' },
				{ text: 'Year', value: 'year' },
				{ text: 'Progress', value: 'progress' },
			],
		};
	},

	mounted() {
		this.$store.dispatch('get', 'goal');

		let goal = { name: '' };

		this.$store.commit('SET_EDITED_ITEM', goal);
		this.$store.commit('SET_DEFAULT_ITEM', goal);
	},

	computed: {
		offices() {
			let functions = this.$store.getters.offices;

			if (functions.indexOf('Group') === -1) functions.push('Group');

			return functions;
		},
		goals() {
			return this.$store.getters.goals;
		},
		goal: {
			get() {
				return this.$store.state.editedItem;
			},
			set(value) {
				this.$store.commit('SET_EDITED_ITEM', value);
			},
		},
	},
};
</script>

<style></style>
