<template>
	<data-table :headers="headers" :items="groups" itemType="group" :item="group">
		<template v-slot:addItem>
			<v-row>
				<v-col>
					<v-text-field v-model="group.name" label="Group name"></v-text-field>
				</v-col>
			</v-row>
		</template>
	</data-table>
</template>

<script>
import DataTable from '../components/DataTable.vue';
export default {
	components: { DataTable },
	data() {
		return {
			headers: [{ text: 'Name', value: 'name' }],
		};
	},
	mounted() {
		this.$store.dispatch('get', 'group');

		let group = { name: '' };

		this.$store.commit('SET_EDITED_ITEM', group);
		this.$store.commit('SET_DEFAULT_ITEM', group);
	},
	computed: {
		groups() {
			return this.$store.getters.groups;
		},
		group: {
			get() {
				return this.$store.state.editedItem;
			},
			set(value) {
				this.$store.commit('SET_EDITED_ITEM', value);
			},
		},
	},
};
</script>

<style></style>
