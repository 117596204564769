import '@fortawesome/fontawesome-free/css/all.css'; // Ensure you are using css-loader
import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';

Vue.config.productionTip = false;

new Vue({
	router,
	store,
	vuetify,
	icons: {
		iconfont: 'fa',
	},

	render: function(h) {
		return h(App);
	},
}).$mount('#app');
