<template>
	<data-table
		:headers="headers"
		:items="exchangeRates"
		itemType="ExchangeRate"
		:item="exchangeRate"
	>
		<template v-slot:addItem>
			<v-row>
				<v-col>
					<v-select
						:items="currenciesNoGbp"
						item-text="name"
						item-value="id"
						v-model="exchangeRate.currencyFromId"
						label="Currency From"
					></v-select>
				</v-col>
			</v-row>
			<v-row>
				<v-col>
					<v-select
						readonly
						:items="currencies"
						item-text="name"
						item-value="id"
						v-model="exchangeRate.currencyToId"
						label="Currency To"
					></v-select>
				</v-col>
			</v-row>
			<v-row>
				<v-col>
					<v-text-field v-model.number="exchangeRate.rate" label="Rate"></v-text-field>
				</v-col>
			</v-row>
			<v-row>
				<v-col>
					<v-text-field v-model.number="exchangeRate.year" label="Year"></v-text-field>
				</v-col>
			</v-row>
		</template>
	</data-table>
</template>

<script>
import DataTable from '../components/DataTable.vue';
export default {
	components: { DataTable },
	data() {
		return {
			headers: [
				{ text: 'Currency From', value: 'currencyFromName' },
				{ text: 'Currency To', value: 'currencyToName' },
				{ text: 'Rate', value: 'rate' },
				{ text: 'Year', value: 'year' },
			],
		};
	},
	mounted() {
		Promise.all([
			this.$store.dispatch('get', 'ExchangeRate'),
			this.$store.dispatch('get', 'Currency'),
		]).then(() => {
			let exchangeRate = {
				currencyFromId: '',
				currencyToId: this.gbp.id,
				currencyFromName: '',
				currencyToName: '',
				rate: '',
				year: '',
			};

			this.$store.commit('SET_EDITED_ITEM', exchangeRate);
			this.$store.commit('SET_DEFAULT_ITEM', exchangeRate);
		});
	},
	computed: {
		exchangeRates() {
			return this.$store.getters.exchangeRates;
		},
		exchangeRate: {
			get() {
				return this.$store.state.editedItem;
			},
			set(value) {
				this.$store.commit('SET_EDITED_ITEM', value);
			},
		},
		currencies() {
			return this.$store.getters.currencies;
		},
		currenciesNoGbp() {
			return this.$store.getters.currencies.filter((x) => x.code !== 'GBP');
		},
		gbp() {
			return this.currencies.find((x) => x.code === 'GBP');
		},
	},
};
</script>

<style></style>
