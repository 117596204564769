<template>
	<v-card class="mb-2">
		<v-card-title>
			{{ label }}
			<v-spacer></v-spacer>
			<v-btn v-if="multiplePerDay" text color="green" @click="addAnother">
				<v-icon small class="mr-2">fas fa-plus</v-icon>
				Add Another
			</v-btn>
			<v-tooltip bottom>
				<template v-slot:activator="{ on, attrs }">
					<v-btn v-bind="attrs" v-on="on" color="red" icon @click="remove">
						<v-icon>fas fa-times</v-icon>
					</v-btn>
				</template>
				<span>Delete Activity</span>
			</v-tooltip>
		</v-card-title>
		<v-card-subtitle>{{ rules }}</v-card-subtitle>
		<v-card-text>
			<v-row>
				<v-col>
					<v-textarea label="User Comment" v-model="activity.userComment" @change="change" dense></v-textarea>
				</v-col>
			</v-row>

			<v-row v-if="isAdminOrManager">
				<v-col>
					<v-textarea label="Manager Comment" v-model="activity.managerComment" @change="change" dense></v-textarea>
				</v-col>
			</v-row>

			<v-row v-if="commissionBased">
				<v-col>
					<v-text-field label="Value" v-model.number="activity.commissionValue" @change="change"></v-text-field>
				</v-col>
				<v-col>
					<v-select
						:items="currencies"
						item-text="code"
						item-value="id"
						v-model="activity.commissionCurrencyId"
						label="Currency"
						@change="change"
					></v-select>
				</v-col>
			</v-row>

			<v-row v-if="numericalBased">
				<v-col>
					<v-text-field :label="numericalLabel" v-model.number="activity.numericalValue" @change="change"></v-text-field>
				</v-col>
			</v-row>
		</v-card-text>
	</v-card>
</template>

<script>
export default {
	props: {
		name: String,
		activityId: String,
		activityVersionId: String,
		userComment: String,
		managerComment: String,
		id: String,
		commissionBased: Boolean,
		commissionValue: Number,
		commissionCurrencyId: String,
		rules: String,
		numericalLabel: String,
		numericalBased: Boolean,
		numericalValue: Number,
		multiplePerDay: Boolean,
	},
	name: 'UserActivityDescription',
	data() {
		return {
			activity: {
				id: this.id,
				activityVersionId: this.activityVersionId,
				activityId: this.activityId,
				userComment: this.userComment,
				managerComment: this.managerComment,
				commissionValue: this.commissionValue,
				commissionCurrencyId: this.commissionCurrencyId,
				numericalValue: this.numericalValue,
				multiplePerDay: this.multiplePerDay,
			},
			label: 'Info for "' + this.name + '"',
		};
	},

	mounted() {
		if (this.commissionBased) {
			this.$store.dispatch('get', 'Currency').then(() => {
				this.activity.commissionCurrencyId = this.commissionCurrencyId;
			});
		}

		if (!this.id) this.activity.id = crypto.randomUUID();
	},
	methods: {
		remove() {
			this.$emit('remove', this.activity.id);
		},
		change() {
			this.$emit('change', this.activity);
		},
		addAnother() {
			this.$emit('addAnother', {
				activityVersionId: this.activityVersionId,
				activityId: this.activityId,
				commissionCurrencyId: this.commissionCurrencyId,
				name: this.name,
				numericalLabel: this.numericalLabel,
				multiplePerDay: this.multiplePerDay,
			});
		},
	},
	computed: {
		currencies() {
			return this.$store.getters.currencies;
		},
		isAdminOrManager() {
			return this.$store.getters.isAdminOrManager;
		},
	},
};
</script>

<style></style>
