<template>
	<div class="chart-container">
		<canvas :id="id"></canvas>
	</div>
</template>

<script>
import Chart from 'chart.js/auto';

export default {
	props: {
		chartData: Object,
	},

	data() {
		return { chart: {} };
	},

	methods: {
		init() {
			const ctx = document.getElementById(this.id);

			this.chart = new Chart(ctx, {
				type: 'bar',
				data: this.chartData,

				options: {
					maintainAspectRatio: false,
					indexAxis: 'y',
					elements: {
						bar: {
							backgroundColor: 'rgba(21, 101, 192, 0.8)',
						},
					},

					scales: {
						x: {
							title: {
								display: true,
								text: 'Points',
								font: {
									size: 15,
								},
							},
						},
					},

					plugins: {
						legend: {
							display: false,
						},
					},
				},
			});

			this.chart.update();
		},
	},

	computed: {
		id() {
			return this._uid;
		},
	},

	mounted() {
		this.init();
	},
};
</script>

<style>
.chart-container {
	position: relative;

	height: 40vh;
}
</style>
