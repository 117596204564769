<template>
	<div>
		<v-dialog v-model="dialog" persistent max-width="500">
			<template v-slot:activator="{ on, attrs }">
				<v-btn text v-bind="attrs" v-on="on">
					Reset Password
				</v-btn>
			</template>
			<v-card>
				<v-card-title>
					Password Reset
				</v-card-title>
				<v-form>
					<v-card-text>
						<v-container>
							<v-row>
								<v-col>
									<v-text-field
										v-if="!reset"
										v-model="email"
										:rules="emailRules"
										label="Email Address"
									></v-text-field>
								</v-col>
							</v-row>
							<v-row>
								<v-col>
									<v-text-field
										v-if="reset"
										v-model="newPassword"
										:rules="newPasswordRules"
										label="New Password"
										:append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
										:type="show2 ? 'text' : 'password'"
										@click:append="show2 = !show2"
									></v-text-field>
								</v-col>
							</v-row>
							<v-row>
								<v-col>
									<v-text-field
										v-if="reset"
										v-model="repeatPassword"
										:rules="repeatPasswordRules"
										label="Repeat New Password"
										:append-icon="show3 ? 'mdi-eye' : 'mdi-eye-off'"
										:type="show3 ? 'text' : 'password'"
										@click:append="show3 = !show3"
									></v-text-field>
								</v-col>
							</v-row>
						</v-container>
					</v-card-text>
					<v-card-actions>
						<v-btn color="darken-1" text @click="dialog = false">
							Cancel
						</v-btn>
						<v-spacer></v-spacer>
						<v-btn color="green darken-1" text @click="resetPassword">
							Reset
						</v-btn>
					</v-card-actions>
				</v-form>
			</v-card>
		</v-dialog>

		<v-snackbar v-model="resetMessage">
			{{ message }}

			<template v-slot:action="{ attrs }">
				<v-btn color="pink" text v-bind="attrs" @click="resetMessage = false">
					Close
				</v-btn>
			</template>
		</v-snackbar>
	</div>
</template>

<script>
export default {
	data() {
		return {
			email: '',
			oldPassword: '',
			newPassword: '',
			repeatPassword: '',
			dialog: false,
			show1: false,
			show2: false,
			show3: false,
			oldPasswordRules: [(v) => !!v || ' Old Password is required'],
			newPasswordRules: [(v) => !!v || 'New Password is required'],
			repeatPasswordRules: [
				(v) => !!v || 'Please repeat the new password',
				(v) => this.newPassword === v || 'Passwords must match',
			],
			emailRules: [
				(v) => !!v || 'E-mail is required',
				(v) => /.+@.+/.test(v) || 'E-mail must be valid',
			],
			resetMessage: false,
			message: '',
		};
	},
	mounted() {
		if (this.reset) this.dialog = true;
	},
	methods: {
		resetPassword() {
			if (!this.reset) this.$store.dispatch('resetPassword', this.email);
			else
				this.$store
					.dispatch('updatePassword', {
						userId: this.userId,
						newPassword: this.newPassword,
					})
					.then((x) => {
						this.resetMessage = true;
						this.message =
							x.data === 'success'
								? 'Password successfully reset, please login with your new password'
								: 'Something went wrong';
					});

			this.dialog = false;
		},
	},
	computed: {
		reset() {
			return this.$route.query.reset;
		},
		userId() {
			return this.$route.query.userId;
		},
	},
};
</script>

<style></style>
