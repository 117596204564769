<template>
	<v-data-table
		:headers="headersWithActions"
		:items="computedItems"
		class="dataTable elevation-1 pa-4"
		dense
		:search="search"
		:sort-by="[sortBy]"
		:items-per-page="20"
		:sort-desc="sortDesc"
		:footer-props="{
			'items-per-page-options': [5, 10, 15, 20, -1],
		}"
	>
		<template v-slot:top>
			<v-row dense>
				<v-col>
					<span class="text-h4">{{ title }}</span>
				</v-col>
			</v-row>

			<v-row class="mb-4">
				<v-col class="d-flex align-start ">
					<add-item
						v-if="isAdminOrManager && !readOnly"
						:item-type="itemType"
						:items="items"
						:item="itemObject"
						:editedItem="editedItem"
						:deletedItem="deletedItem"
						v-on="$listeners"
						@closeDelete="clearDeletedItem"
						@close="clearEditedItem"
						class="mr-4"
					>
						<slot name="addItem"></slot>
					</add-item>

					<v-btn v-if="showExport" class="green white--text" @click="exportToExcel()">
						<v-icon class="mr-2" small>mdi-file-excel</v-icon>
						Export
					</v-btn>
				</v-col>

				<v-spacer />

				<v-col class="d-flex align-end">
					<v-select v-if="showYearFilter" :items="years" dense hide-details label="Year" v-model="year" @input="$emit('yearChange', year)"></v-select>

					<v-text-field label="Filter..." v-model="search" dense hide-details clearable class="ml-4"></v-text-field>
				</v-col>
			</v-row>
		</template>

		<template v-slot:[`item.points`]="{ item }">
			{{ item.points.toFixed(2) }}
		</template>

		<template v-slot:[`item.actions`]="{ item }">
			<v-icon class="mr-4" @click="editedItem = item">
				fas fa-edit
			</v-icon>

			<v-icon @click="deletedItem = item">
				fas fa-trash-alt
			</v-icon>
		</template>
	</v-data-table>
</template>

<script>
import startCase from 'lodash.startcase';
import pluralize from 'pluralize';
import { utils, writeFile } from 'xlsx';
import AddItem from '../components/AddItem';

export default {
	components: {
		AddItem,
	},

	props: {
		itemType: String,
		items: Array,
		itemObject: Object,
		headers: Array,
		showYearFilter: { type: Boolean, default: false },
		showExport: { type: Boolean, default: false },
		readOnly: { type: Boolean, default: false },
		sortBy: { type: String, default: 'name' },
		sortDesc: { type: Boolean, default: false },
	},

	data() {
		return {
			editedItem: this.itemObject,
			deletedItem: this.itemObject,
			search: '',
			years: [],
			year: new Date().getFullYear(),
		};
	},

	methods: {
		clearDeletedItem() {
			this.deletedItem = this.itemObject;
		},

		clearEditedItem() {
			this.editedItem = this.itemObject;
		},

		exportToExcel() {
			const x = document.querySelectorAll('.dataTable table');
			const wb = utils.table_to_book(document.querySelectorAll('.dataTable table')[0]);

			writeFile(wb, `${this.title}.xlsx`);
		},
	},

	computed: {
		title() {
			return startCase(pluralize(this.itemType));
		},

		computedItems() {
			if (this.showYearFilter && this.year) return this.items.filter((x) => x.year === this.year);

			return this.items;
		},

		headersWithActions() {
			let headersWithActions = this.headers.slice();

			if (this.readOnly) return this.headers;

			if (this.isGoalsAdmin)
				headersWithActions.push({
					text: 'Actions',
					value: 'actions',
					sortable: false,
					width: '10%',
				});
			else headersWithActions = headersWithActions.filter((x) => x.permission !== 'AdminOnly');

			return headersWithActions;
		},

		isAdminOrManager() {
			return this.$store.getters.isAdminOrManager;
		},

		isGoalsAdmin() {
			return this.$store.getters.isGoalsAdmin;
		},
	},

	async mounted() {
		const result = await this.$store.dispatch('GetYears');

		this.years = result.data;
	},
};
</script>

<style></style>
