<template>
	<v-layout child-flex>
		<v-card>
			<v-card-title class="text-h4">Points Analysis</v-card-title>

			<v-divider></v-divider>

			<v-card-text>
				<v-row>
					<v-col cols="2">
						<v-select :items="years" v-model="year" label="Year"></v-select>
					</v-col>
				</v-row>

				<v-row>
					<v-col>
						<v-expansion-panels multiple v-if="!loading">
							<v-expansion-panel v-for="(office, i) in reportData" :key="i">
								<v-expansion-panel-header>
									<span class="text-subtitle-1">
										{{ office.office }} | {{ Math.round(office.totalPoints) }} points
									</span>
								</v-expansion-panel-header>
								<v-divider></v-divider>
								<v-expansion-panel-content>
									<v-row>
										<v-col cols="12">
											<p class="text-h6 my-4">Office Overview</p>
											<bar-chart :chartData="getChartData(office)"></bar-chart>
										</v-col>
									</v-row>

									<v-row>
										<v-col>
											<p class="text-h6 my-4">User Overview</p>
											<v-expansion-panels multiple>
												<v-expansion-panel v-for="(user, i) in office.userData" :key="i">
													<v-expansion-panel-header>
														<span class="text-subtitle-1">
															{{ user.name }} | {{ Math.round(user.totalPoints) }} points
														</span>
													</v-expansion-panel-header>
													<v-divider></v-divider>
													<v-expansion-panel-content>
														<v-row>
															<v-col cols="12">
																<bar-chart :chartData="getChartData(user)"></bar-chart>
															</v-col>
														</v-row>
													</v-expansion-panel-content>
												</v-expansion-panel>
											</v-expansion-panels>
										</v-col>
									</v-row>
								</v-expansion-panel-content>
							</v-expansion-panel>
						</v-expansion-panels>
					</v-col>
				</v-row>
			</v-card-text>
		</v-card>

		<v-overlay :value="loading">
			<v-progress-circular indeterminate size="128"></v-progress-circular>
		</v-overlay>
	</v-layout>
</template>

<script>
import BarChart from '../components/BarChart.vue';

export default {
	components: { BarChart },

	data() {
		return { reportData: [], years: [], year: 0, loading: false };
	},

	methods: {
		async getYears() {
			const result = await this.$store.dispatch('GetYears');

			this.years = result.data;

			this.year = this.years[0];
		},

		async getData() {
			this.loading = true;
			const result = await this.$store.dispatch('GetReportData', this.year);

			this.reportData = result.data;
			this.loading = false;
		},

		getChartData(raw) {
			return {
				labels: raw.pointsData.map((x) => x.activity),
				datasets: [
					{
						data: raw.pointsData.map((x) => x.points),
					},
				],
			};
		},
	},

	watch: {
		async year() {
			await this.getData();
		},
	},

	async mounted() {
		await this.getYears();
	},
};
</script>

<style></style>
