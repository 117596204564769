<template>
	<v-dialog v-model="dialog" width="50%">
		<v-card>
			<v-card-title>
				<span class="headline">{{ formTitle }}</span>
			</v-card-title>
			<v-card-text>
				<v-container>
					<v-row>
						<v-col>
							<v-menu v-model="menu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
								<template v-slot:activator="{ on, attrs }">
									<v-text-field
										v-model="dateDisplay"
										label="Select Date..."
										prepend-icon="fas fa-calendar-day"
										readonly
										v-bind="attrs"
										v-on="on"
									></v-text-field>
								</template>
								<v-date-picker v-model="dateEvents.date" @input="menu = false"></v-date-picker>
							</v-menu>
						</v-col>
					</v-row>
					<v-row>
						<v-col>
							<v-autocomplete
								id="activitiesSelect"
								:value="uniqueUserActivities"
								@change="update"
								:items="groupedActivities"
								dense
								chips
								small-chips
								label="Activities"
								multiple
								prepend-icon="far fa-calendar-plus"
								item-text="name"
								item-value="activityVersionId"
								return-object
								:menu-props="{
									maxHeight: 550,
									attach: '#activitiesSelect',
									bottom: true,
									offsetY: true,
									allowOverflow: true,
								}"
							></v-autocomplete>
						</v-col>
					</v-row>
					<v-row>
						<v-col>
							<component v-for="description in descriptions" :is="description" :key="description.label"></component>
						</v-col>
					</v-row>
				</v-container>
			</v-card-text>
			<v-card-actions>
				<v-btn @click="close">
					Close
				</v-btn>
				<v-spacer></v-spacer>
				<v-btn color="success" dark @click="save">
					Save
				</v-btn>
			</v-card-actions>
		</v-card>

		<v-snackbar v-model="error" :timeout="3000">
			{{ errorMessage }}

			<template v-slot:action="{ attrs }">
				<v-btn color="pink" text v-bind="attrs" @click="clearError">
					Close
				</v-btn>
			</template>
		</v-snackbar>
	</v-dialog>
</template>

<script>
import { DateTime } from 'luxon';
import UserActivityDescription from './UserActivityDescription';

export default {
	data: () => ({
		dialog: false,
		menu: null,
		items: [],
		values: [],
		descriptions: [],
	}),

	watch: {
		'dateEvents'(val) {
			if (val.init !== undefined && !val.init) this.dialog = true;
		},

		'userActivities'(val, oldVal) {
			let added = [...val.filter((x) => !oldVal.includes(x))];

			added.forEach((x) => {
				this.editComponent(x);
			});
		},

		'dialog'(val) {
			val || this.close();
		},
		'dateEvents.date'(val) {
			if (!val) return;
			this.$store.dispatch('GetGroupedActivities', parseInt(val.substring(0, 4)));
		},
	},

	methods: {
		removeActivity(id) {
			this.dateEvents.activities = this.dateEvents.activities.filter((x) => x.id !== id);
		},

		update(value) {
			value.forEach((x) => {
				x.id = crypto.randomUUID();
			});

			let newValue = value.filter((x) => !this.uniqueUserActivities.map((x) => x.activityVersionId).includes(x.activityVersionId));

			let newArray = this.userActivities.slice();

			this.dateEvents.activities = newArray.concat(newValue);
		},

		clearError() {
			this.$store.commit('CLEAR_ERROR');
		},

		editComponent(activity) {
			let self = this;

			const renderComponent = {
				render(h) {
					if (self.userActivityIds.includes(activity.id)) {
						return h(UserActivityDescription, {
							props: {
								userComment: activity.userComment,
								managerComment: activity.managerComment,
								name: activity.name,
								activityId: activity.activityId,
								activityVersionId: activity.activityVersionId,
								id: activity.id,
								commissionBased: activity.commissionBased,
								commissionCurrencyId: activity.commissionCurrencyId,
								commissionValue: activity.commissionValue,
								numericalBased: activity.numericalBased,
								numericalLabel: activity.numericalLabel,
								numericalValue: activity.numericalValue,
								rules: activity.rules,
								multiplePerDay: activity.multiplePerDay,
							},
							on: {
								change: (info) => self.addInfo(info),
								addAnother: (info) => self.addBlankActivity(info),
								remove: (info) => self.removeActivity(info),
							},
						});
					}
				},
			};

			this.descriptions.push(renderComponent);
		},

		close() {
			this.dialog = false;
			this.groupedActivities.forEach((x) => {
				delete x.userComment;
				delete x.managerComment;
			});
			this.$store.commit('CLEAR_EDITED_ITEM');
			this.descriptions = [];
		},

		async save() {
			await this.$store.dispatch('add', 'useractivities', this.dateEvents);

			if (!this.error) this.close();
		},

		addInfo(info) {
			let userActivity;

			if (info.multiplePerDay) userActivity = this.dateEvents.activities.find((x) => x.id === info.id);
			else userActivity = this.dateEvents.activities.find((x) => x.activityVersionId === info.activityVersionId);

			if (!userActivity) this.dateEvents.activities.push(info);
			else {
				userActivity.userComment = info.userComment;
				userActivity.managerComment = info.managerComment;
				userActivity.commissionCurrencyId = info.commissionCurrencyId;
				userActivity.commissionValue = info.commissionValue;
				userActivity.numericalValue = info.numericalValue;
			}
		},

		addBlankActivity(activity) {
			let self = this;

			activity.id = crypto.randomUUID();

			const renderComponent = {
				render(h) {
					return h(UserActivityDescription, {
						props: {
							id: activity.id,
							name: activity.name,
							activityId: activity.activityId,
							activityVersionId: activity.activityVersionId,
							commissionBased: activity.commissionBased,
							numericalBased: activity.numericalBased,
							numericalLabel: activity.numericalLabel,
							rules: activity.rules,
							multiplePerDay: activity.multiplePerDay,
						},
						on: {
							change: function(info) {
								self.addInfo(info);
							},
							addAnother: function(info) {
								self.addBlankActivity(info);
							},
							remove: function(info) {
								self.removeActivity(info);
							},
						},
					});
				},
			};

			this.descriptions.push(renderComponent);
			this.dateEvents.activities.push(activity);
		},
	},

	async mounted() {
		await this.$store.dispatch('GetGroupedActivities', this.year);

		let userActivity = {
			userId: '',
			date: DateTime.utc().toISODate(),
			activities: [],
		};

		this.$store.commit('SET_EDITED_ITEM', userActivity);
		this.$store.commit('SET_DEFAULT_ITEM', userActivity);
	},

	computed: {
		error: {
			get() {
				return this.$store.getters.error;
			},
			set() {
				this.$store.commit('CLEAR_ERROR');
			},
		},

		errorMessage() {
			return this.$store.getters.errorMessage;
		},

		formTitle() {
			return this.dateEvents.activities ? (this.dateEvents.activities.length === 0 ? 'Add Activities' : 'Edit Activities') : 'Add Activities';
		},

		groupedActivities() {
			const groupedActivities = this.$store.getters.groupedActivities;
			const groups = Object.keys(groupedActivities);
			let formatted = [];

			groups.forEach((g) => {
				formatted.push({ header: g });

				const groupActivities = groupedActivities[g];

				groupActivities.forEach((x) => {
					if (x.rules) x.name = `${x.name} | ${x.rules}`;
				});

				formatted = formatted.concat(groupActivities);
				formatted.push({ divider: true });
			});

			return formatted;
		},

		groups() {
			return this.$store.getters.groups;
		},

		dateDisplay() {
			return DateTime.fromFormat(this.dateEvents.date, 'yyyy-LL-dd').toFormat('dd/MM/yyyy');
		},

		dateEvents: {
			get() {
				return this.$store.getters.editedItem;
			},
			set(value) {
				this.$store.commit('SET_EDITED_ITEM', value);
			},
		},

		userActivities() {
			return this.dateEvents.activities ? this.dateEvents.activities : [];
		},

		uniqueUserActivities() {
			let unique = this.userActivityVersionIds.filter((v, i, a) => a.indexOf(v) === i);

			return this.groupedActivities.filter((x) => unique.includes(x.activityVersionId));
		},

		userActivityVersionIds() {
			return this.dateEvents.activities ? this.dateEvents.activities.map((x) => x.activityVersionId) : [];
		},

		userActivityIds() {
			return this.dateEvents.activities ? this.dateEvents.activities.map((x) => x.id) : [];
		},

		month() {
			return this.$store.getters.month;
		},

		year() {
			return this.$store.getters.year;
		},
	},
};
</script>
<style lang="scss" scoped>
.v-dialog__content {
	align-items: flex-start;
	justify-content: center;
}
</style>
