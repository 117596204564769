<template>
	<div>
		<v-dialog v-model="dialog" width="50%">
			<template v-slot:activator="{ on, attrs }">
				<v-btn color="success darken-2 mr-2" v-on="on" :attrs="attrs">
					<span>{{ pointsThisYear }} Points in {{ year }}</span>
				</v-btn>
			</template>

			<v-card>
				<v-card-title>Points Summary</v-card-title>
				<v-card-text>
					<v-card v-for="{ year, pointsData } in points" :key="year" class="mb-5">
						<v-card-title>{{ year }}</v-card-title>
						<v-card-text>
							<v-data-table
								dense
								hide-default-footer
								:title="year"
								:items="pointsData"
								:headers="[
									{ text: 'Activity', value: 'activity' },
									{ text: 'Points', value: 'points' },
								]"
							></v-data-table>
						</v-card-text>
					</v-card>
				</v-card-text>

				<v-card-actions>
					<v-btn @click="dialog = false">Close</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
export default {
	props: {
		points: { type: Array },
	},

	data() {
		return {
			dialog: false,
		};
	},

	computed: {
		pointsThisYear() {
			return this.points?.find((x) => x.year === this.year)?.totalPoints || 0;
		},

		year() {
			return this.$store.getters.year;
		},
	},
};
</script>

<style></style>
