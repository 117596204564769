import axios from 'axios';
import Vue from 'vue';
import Vuex from 'vuex';
import router from '../router/index.js';
import { api } from '../utils/api.js';

Vue.use(Vuex);

axios.defaults.withCredentials = true;
axios.defaults.baseURL = process.env.VUE_APP_API_URL;
axios.defaults.headers.get['Cache-Control'] = 'no-cache, no-store';

const store = new Vuex.Store({
	state: {
		isAuthenticated: false,
		user: {
			id: '',
			name: '',
			role: '',
			points: [],
		},
		calendarUser: { id: '', name: '', points: [] },
		groupedActivities: {},
		activities: [],
		groups: [],
		userActivities: [],
		exchangeRates: [],
		currencies: [],
		users: [],
		managers: [],
		goals: [],
		league: [],
		month: '',
		year: new Date().getFullYear(),
		editedItem: {},
		defaultItem: {},
		offices: ['Group', 'Aberdeen', 'Norway', 'Brazil'],
		roles: ['User', 'Admin', 'Manager', 'Goals', 'GoalsAdmin'],
		loginResponse: {},
		error: false,
		errorMessage: '',
	},
	mutations: {
		SET_LOGGEDIN_USER(state, user) {
			state.user = user;

			state.isAuthenticated = true;

			let homePage = this.getters.user.role === 'Goals' ? 'goalsProgress' : 'home';

			if (user.redirect) router.push(homePage);
		},

		ON_NOT_AUTHENTICATED(state) {
			state.user = {
				id: '',
				name: '',
				role: '',
			};

			state.isAuthenticated = false;
		},

		SET_ERROR(state, error) {
			state.error = true;
			state.errorMessage = error;
		},

		CLEAR_ERROR(state) {
			state.error = false;
			state.errorMessage = '';
		},

		SET_GROUPED_ACTIVITIES(state, activities) {
			state.groupedActivities = activities;
		},

		SET_ACTIVITIES(state, activities) {
			state.activities.splice(0, state.activities.length, ...activities);
		},

		SET_CALENDAR_USER_POINTS(state, points) {
			state.calendarUser.points.splice(0, state.calendarUser.points.length, ...points);
		},

		SET_CALENDAR_USER(state, calendarUser) {
			state.calendarUser = calendarUser;
		},

		SET_USERS(state, users) {
			state.users = users;
		},

		SET_MANAGERS(state, managers) {
			state.managers = managers;
		},

		SET_GROUPS(state, groups) {
			state.groups = groups;
		},

		SET_USER_ACTIVITIES(state, userActivities) {
			state.userActivities = userActivities;
		},

		SET_EXCHANGE_RATES(state, exchangeRates) {
			state.exchangeRates = exchangeRates;
		},

		SET_CURRENCIES(state, currencies) {
			state.currencies = currencies;
		},

		SET_LEAGUE(state, league) {
			state.league = league;
		},

		SET_GOALS(state, goals) {
			state.goals = goals;
		},

		SET_MONTH(state, month) {
			state.month = month;
		},

		SET_YEAR(state, year) {
			state.year = year;
		},

		SET_EDITED_ITEM(state, item) {
			state.editedItem = Object.assign({}, item);
		},

		SET_DEFAULT_ITEM(state, item) {
			state.defaultItem = Object.assign({}, item);
		},

		CLEAR_EDITED_ITEM(state) {
			state.editedItem = Object.assign({}, state.defaultItem);
		},

		SET_LOGIN_RESPONSE(state, response) {
			state.loginResponse = response;
		},
	},

	actions: {
		async getUserPoints({ commit }, userId) {
			const result = await axios.get(`User/GetPoints/${userId}`);

			commit('SET_CALENDAR_USER_POINTS', result.data);
		},

		async GetYears() {
			return axios.get('Reporting/GetYears');
		},

		async GetPoints({ commit }, year) {
			const result = await axios.get(`Activity/GetPoints/${year}`);

			commit('SET_LEAGUE', result.data);
		},

		async GetActivities({ commit }, year) {
			const result = await axios.get(`Activity/GetActivities/${year}`);

			commit('SET_ACTIVITIES', result.data);
		},

		async GetGroupedActivities({ commit }, year) {
			const result = await axios.get(`Activity/GetGroupedActivities/${year}`);

			commit('SET_GROUPED_ACTIVITIES', result.data);
		},

		async GetReportData({ commit }, year) {
			return axios.get('Reporting/GetReportData', { params: { year: year } });
		},

		async resetPassword({ commit }, email) {
			return axios.post('User/ResetPassword', null, {
				params: {
					email: email,
				},
			});
		},

		async updatePassword({ commit }, payload) {
			return axios.post('User/UpdatePassword', null, {
				params: {
					userId: payload.userId,
					newPassword: payload.newPassword,
				},
			});
		},

		async login({ commit }, payload) {
			return axios
				.post('User/Login', null, {
					params: {
						email: payload.email,
						password: payload.password,
						rememberMe: payload.rememberMe,
					},
				})
				.then((response) => {
					let data = response.data;
					data.redirect = true;

					commit('SET_LOGGEDIN_USER', data);
				})

				.catch((err) => {
					if (err.response) {
						commit('SET_LOGIN_RESPONSE', err.response);
					} else if (err.request) {
						commit('SET_LOGIN_RESPONSE', err.request);
					} else {
						commit('SET_LOGIN_RESPONSE', err);
					}
				});
		},

		tokenLogin({ commit }, data) {
			axios
				.post(`sso/Login?token=${router.currentRoute.query.token}`)
				.then((response) => {
					let data = response.data;
					data.redirect = true;

					commit('SET_LOGGEDIN_USER', data);
				})
				.catch((err) => {
					if (err.response) {
						commit('SET_LOGIN_RESPONSE', err.response);
					} else if (err.request) {
						commit('SET_LOGIN_RESPONSE', err.request);
					} else {
						commit('SET_LOGIN_RESPONSE', err);
					}
				});
		},

		async logout({ commit }) {
			commit('ON_NOT_AUTHENTICATED');

			await axios.post(`user/logout`);

			router.push('login');
		},

		async add({ commit, dispatch }, type) {
			return await axios.post(api.getRoute(type, 'C'), this.getters.editedItem).then((x) => {
				if (x.data.error) commit('SET_ERROR', x.data.message);
				else
					switch (type) {
						case 'useractivities':
							dispatch('getUserActivities');
							break;
						case 'user':
							dispatch('get', 'Managers');
							break;
						default:
							dispatch('get', type);
					}
			});
		},

		async get({ commit }, type) {
			return axios.get(api.getRoute(type, 'R')).then((response) => {
				commit(api.getSetter(type), response.data);
			});
		},

		async getGoals({ commit }, year) {
			let goals = await axios.get(`Goal/Get/${year}`);

			commit('SET_GOALS', goals.data);
		},

		async update({ commit, dispatch }, type) {
			await axios.post(api.getRoute(type, 'U'), this.getters.editedItem);
			dispatch('get', type);
		},

		async delete({ commit, dispatch }, type) {
			switch (type) {
				case 'activity':
					await axios.post(`${api.getRoute(type, 'D')}`, this.getters.editedItem);
					break;
				default:
					await axios.post(`${api.getRoute(type, 'D')}/${this.getters.editedItem.id}`);
					break;
			}

			dispatch('get', type);
		},

		async getUserActivities({ commit }) {
			return axios
				.get('Activity/GetUserActivities', {
					params: {
						userId: this.getters.calendarUser.id,
						month: this.getters.month,
						year: this.getters.year,
					},
				})
				.then((response) => {
					commit('SET_USER_ACTIVITIES', response.data);
				});
		},

		async setUserActivityStatus({ commit }, payload) {
			await axios.post('Activity/SetUserActivityStatus', null, {
				params: {
					userActivityId: payload.userActivityId,
					status: payload.status,
					managerComment: payload.managerComment,
					managerOnlyComment: payload.managerOnlyComment,
					userComment: payload.userComment,
				},
			});

			await this.dispatch('getUserActivities');
		},

		async updateUserComment({ commit }, payload) {
			return axios
				.post('Activity/UpdateUserComment', null, {
					params: {
						userActivityId: payload.userActivityId,
						userComment: payload.userComment,
					},
				})
				.then((x) => this.dispatch('getUserActivities'));
		},

		async authenticate({ commit }, opts) {
			return await axios
				.get(`User/Authenticate`)
				.then((response) => {
					const data = response.data;
					if (data.id && data.role && data.name) {
						data.redirect = opts.redirect;
						commit('SET_LOGGEDIN_USER', data);
						return true;
					} else {
						store.commit('ON_NOT_AUTHENTICATED');
						return false;
					}
				})
				.catch(() => {
					store.commit('ON_NOT_AUTHENTICATED');
					return false;
				});
		},
	},

	getters: {
		activities: (state) => state.activities,
		groupedActivities: (state) => state.groupedActivities,
		groups: (state) => state.groups,
		userActivities: (state) => state.userActivities,
		league: (state) => state.league,
		month: (state) => state.month,
		year: (state) => state.year,
		editedItem: (state) => state.editedItem,
		defaultItem: (state) => state.defaultItem,
		user: (state) => state.user,
		goals: (state) => state.goals,
		exchangeRates: (state) => state.exchangeRates,
		currencies: (state) => state.currencies,
		users: (state) => state.users,
		managers: (state) => state.managers,
		isAdmin: (state) => state.user.role === 'Admin',
		isAdminOrManager: (state) => state.user.role === 'Admin' || state.user.role === 'Manager',
		isGoalsAdmin: (state) => state.user.role === 'GoalsAdmin' || state.user.role === 'Manager' || state.user.role === 'Admin',
		isGoalsUser: (state) => state.user.role === 'Goals',
		calendarUser: (state) => state.calendarUser,
		offices: (state) => state.offices,
		roles: (state) => state.roles,
		loginResponse: (state) => state.loginResponse,
		error: (state) => state.error,
		errorMessage: (state) => state.errorMessage,
	},
});

export default store;
