<template>
	<v-app>
		<app-bar v-if="this.$store.state.isAuthenticated"></app-bar>

		<v-main class="blue-grey lighten-5">
			<router-view></router-view>
		</v-main>
	</v-app>
</template>

<script>
import AppBar from './components/AppBar.vue';

export default {
	name: 'App',

	components: {
		AppBar,
	},

	watch: {
		$route: {
			immediate: true,
			handler(to) {
				document.title = to.meta.title || 'SeaGoal';
			},
		},
	},
};
</script>

<style>
html {
	overflow-y: auto;
}
</style>
