<template>
	<data-table :headers="headers" :items="currencies" itemType="Currency" :item="currency">
		<template v-slot:addItem>
			<v-row>
				<v-col>
					<v-text-field v-model="currency.name" label="Name"></v-text-field>
				</v-col>
			</v-row>
			<v-row>
				<v-col>
					<v-text-field v-model="currency.code" label="Code"></v-text-field>
				</v-col>
			</v-row>
		</template>
	</data-table>
</template>

<script>
import DataTable from '../components/DataTable.vue';
export default {
	components: { DataTable },
	data() {
		return {
			headers: [
				{ text: 'Name', value: 'name' },
				{ text: 'Code', value: 'code' },
			],
		};
	},
	mounted() {
		this.$store.dispatch('get', 'Currency');

		let currency = { name: '', code: '' };

		this.$store.commit('SET_EDITED_ITEM', currency);
		this.$store.commit('SET_DEFAULT_ITEM', currency);
	},
	computed: {
		currencies() {
			return this.$store.getters.currencies;
		},
		currency: {
			get() {
				return this.$store.state.editedItem;
			},
			set(value) {
				this.$store.commit('SET_EDITED_ITEM', value);
			},
		},
	},
};
</script>

<style></style>
